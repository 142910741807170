import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddApplication } from '../../../hooks/queries/useAddApplication';
import { DrawerForm } from './Drawer';
import { FormApplication } from './FormApplication';

export function NewApplication() {
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const newApplication = t('label.newApplication');
  const { addApplication, loadingAddApplication } = useAddApplication();

  const closeDrawer = () => setOpenDrawer(false);

  return (
    <div style={{ textAlign: 'center' }}>
      <Button
        type="default"
        icon={<PlusOutlined />}
        onClick={() => setOpenDrawer(true)}
        style={{ marginBottom: 10, marginTop: 10 }}
      >
        {newApplication}
      </Button>
      <DrawerForm
        title={newApplication}
        loading={loadingAddApplication}
        open={openDrawer}
        closeDrawer={closeDrawer}
      >
        {openDrawer && (
          <FormApplication
            closeDrawer={closeDrawer}
            addApplication={addApplication}
            loadingAddApplication={loadingAddApplication}
          />
        )}
      </DrawerForm>
    </div>
  );
}

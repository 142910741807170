import { useQuery } from '@tanstack/react-query';
import { Service } from '../../services/instance.service';

interface UnreadMessages {
  value: number;
}

export const useUnreadMessages = () => {
  const { data } = useQuery({
    queryKey: ['get unreadMessages'],
    refetchInterval: 1000 * 60,
    queryFn: () => Service.getAll<UnreadMessages[]>('/client/unread'),
    select: ({ data }) => data?.[0]?.value,
  });

  return {
    unreadMessagesCount: data,
  };
};

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useState } from 'react';
import './CKEditor.css';
interface RedactorComponentProps {
  onChangeToHtml: (textHtml: string) => void;
}

export function RedactorComponent({ onChangeToHtml }: RedactorComponentProps) {
  const [stateEditor, setStateEditor] = useState<string>();

  const handleChange = (value: string) => {
    setStateEditor(value);
    onChangeToHtml(value);
  };
  return (
    <CKEditor
      editor={ClassicEditor}
      data={stateEditor}
      onChange={(_, editor) => {
        const data = editor.getData();
        handleChange(data);
      }}
    />
  );
}

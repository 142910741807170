import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Service } from '../../services/instance.service';
import { OrganizationsType } from '../../types/organizations';

export const useClientOrganizations = () => {
  const {
    isLoading,
    error,
    data: response,
  } = useQuery({
    queryKey: ['get organizations'],
    queryFn: () => Service.getAll('/client/organizations'),
    onError: (error: AxiosError) => {
      console.log('error', error);
    },
  });

  return {
    isLoadingClientOrganizations: isLoading,
    dataClientOrganizations: response?.data as OrganizationsType[],
    errorClientOrganizations: error,
  };
};

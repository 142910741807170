//priorities?organizationId=4709
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { Service } from '../../services/instance.service';
import { DepartmentsType } from '../../types/departments';

export const useDepartments = () => {
  const [isLoading, setIsLaoding] = useState<boolean>(false);
  const [dataDepartments, setDataDepartments] = useState<DepartmentsType[]>([]);
  const [error, setError] = useState<AxiosError>();

  const sectedById = useCallback(
    async (id: number) => {
      if (isLoading === true) return;
      setIsLaoding(true);
      await Service.getAll(`/client/departments/${id}`)
        .then((resposne: AxiosResponse) => {
          setDataDepartments(resposne.data);
          setIsLaoding(false);
        })
        .catch((error) => {
          setError(error);
          setIsLaoding(false);
        });
    },
    [isLoading]
  );

  return {
    selectIdDepartments: sectedById,
    isLoadingDepartments: isLoading,
    dataDepartments: dataDepartments,
    errorDepartments: error,
  };
};

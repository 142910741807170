import { Layout } from 'antd';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useApplications } from '../../hooks/queries/useApplications';
import { useApplicationsStatuses } from '../../hooks/queries/useApplicationsStatuses';
import { useApplicationsTypes } from '../../hooks/queries/useApplicationsTypes';
import { useClientOrganizations } from '../../hooks/queries/useClientOrganizations';
import { Spinner } from '../Spinner/Spinner';
import { HeaderComponent } from './HeaderComponent';
import './MainLayout.css';
import { Sidebar } from './Sidebar';

const { Content, Footer } = Layout;

export function MainLayout() {
  useApplications({});
  useApplicationsTypes();
  useApplicationsStatuses();
  useClientOrganizations();

  return (
    <Layout>
      <Sidebar />
      <Layout className="main-layout">
        <HeaderComponent />
        <Content className="main-layout-content">
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </Content>
        <Footer style={{ textAlign: 'center', overflow: 'auto' }}>
          SRV24 ©2023 Created by Softering. All rights reserved.
        </Footer>
      </Layout>
    </Layout>
  );
}

import { Drawer, Grid } from 'antd';

interface DrawerFormTypeProps {
  open: boolean;
  closeDrawer: () => void;
  children: React.ReactNode;
  title: string;
  loading?: boolean;
}
const { useBreakpoint } = Grid;
export function DrawerForm({
  title,
  open,
  closeDrawer,
  children,
  loading,
}: DrawerFormTypeProps) {
  const screens = useBreakpoint();
  return (
    <Drawer
      title={title}
      open={open}
      onClose={() => {
        if (loading) return;
        closeDrawer();
      }}
      width={screens.xs ? '100vw' : 560}
    >
      {children}
    </Drawer>
  );
}

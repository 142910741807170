import { Button, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';

interface FooterFormProps {
  form: FormInstance;
  closeDrawer: () => void;
}

export function ApplicationDrawerFooter({
  form,
  closeDrawer,
}: FooterFormProps) {
  const { t } = useTranslation();

  return (
    <div>
      <Button
        style={{ marginRight: 20 }}
        onClick={() => {
          form.resetFields();
          closeDrawer();
        }}
      >
        {t('button.cancel')}
      </Button>
      <Button
        type="primary"
        onClick={() => {
          form.submit();
        }}
      >
        {t('button.create')}
      </Button>
    </div>
  );
}

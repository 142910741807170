import { DeleteOutlined, FileTwoTone } from '@ant-design/icons';
import { Input, UploadFile } from 'antd';
import './CardFile.css';

interface CardFileTypeProps {
  file: UploadFile;
  action: { remove: () => void; preview: () => void; download: () => void };
}

export function CardFile({ file, action }: CardFileTypeProps) {
  return (
    <div
      className="upload-wrapper"
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <FileTwoTone style={{ marginLeft: 10, cursor: 'default' }} width={40} />

        <Input
          className="upload-input"
          style={{
            marginLeft: 10,
          }}
          bordered={false}
          defaultValue={file.name}
          onBlur={(e) => {
            file.name = e.target.value;
          }}
        />
      </div>
      <DeleteOutlined
        style={{ marginRight: 10, color: 'red' }}
        onClick={() => action.remove()}
      />
    </div>
  );
}

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import { Service } from '../../services/instance.service';
import { ApplicationResponse } from './../../types/application';

const APPLICATION_KEY = 'application';

export const useApplication = (id: number) => {
  const { isLoading, data } = useQuery({
    queryKey: [APPLICATION_KEY, id],
    queryFn: () => Service.getById('/client/claims', id),
    select: ({ data }) => data,
    onError: (error: AxiosError) => {
      error?.response?.data &&
        notification.error({ message: JSON.stringify(error.response.data) });
    },
  });

  return {
    applicationLoading: isLoading,
    applicationData: data,
  };
};

export const useApplicationData = () => {
  const queryClient = useQueryClient();

  return {
    invalidateApplication: (id: number) =>
      queryClient.invalidateQueries({
        queryKey: [APPLICATION_KEY, id],
      }),
    setApplicationData: (data: ApplicationResponse) =>
      queryClient.setQueryData([APPLICATION_KEY, data.id], { data }),
  };
};

import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Upload } from 'antd';
import { UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardFile } from './CardFile';

export function UploadComponent({ name = 'files' }: { name?: string }) {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-2);

    // 2. Read from response and show file link
    newFileList = newFileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(newFileList);
    return;
  };

  const normFile = (e: [] | { fileList: {} }) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Form.Item
      name={name}
      label={t('label.addFile')}
      valuePropName="fileList"
      getValueFromEvent={normFile}
      style={{ width: 240 }}
    >
      <Upload
        fileList={fileList}
        listType="text"
        onChange={handleChange}
        customRequest={() => null}
        multiple
        itemRender={(originNode, file, currFileList, action) => (
          <CardFile file={file} action={action} />
        )}
      >
        <Button style={{ marginBottom: 10 }} icon={<UploadOutlined />}>
          {t('button.upload')}
        </Button>
      </Upload>
    </Form.Item>
  );
}

import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { SearchParamsKeys } from '../../constants/searchParamsKeys';
import { Service } from '../../services/instance.service';
import { ApplicationsItemsType } from '../../types/applications';
import { INITIAL_PAGE } from '../useFilters';
import { INITIAL_LIMIT } from './../useFilters';

interface ApplicationsData {
  count: number;
  items: ApplicationsItemsType[];
  limit: number;
  page: number;
  total: number;
}

const APPLICATIONS_KEY = 'applications';

export const useApplications = ({
  page = INITIAL_PAGE,
  limit = INITIAL_LIMIT,
  filters = '',
}) => {
  const { t } = useTranslation();

  const { isLoading, data, isPreviousData } = useQuery({
    queryKey: [APPLICATIONS_KEY, page, limit, filters],
    keepPreviousData: true,
    staleTime: page === INITIAL_PAGE ? 0 : 5000,
    queryFn: () =>
      Service.getAll<ApplicationsData>(
        `/client/claims?${SearchParamsKeys.Page}=${page}&${SearchParamsKeys.Limit}=${limit}${filters}`
      ),
    select: ({ data }) => ({
      ...data,
      items: data.items.map((item) => ({
        key: item.id,
        id: {
          id: item.id,
          displayId: item.displayId,
          unreadMessageCount: item.unreadMessageCount,
        },
        type: t(`table.applications.type.${item.type}`),
        status: {
          title: t(`table.applications.status.${item.status}`),
          status: item.status,
        },
        importance: item.sla[0].priority.name,
        createdAt: dayjs(item.createdAt).format('DD.MM.YYYY HH:mm'),
        closedAt:
          item.closedAt && dayjs(item.closedAt).format('DD.MM.YYYY HH:mm'),
        city: item.targetDepartment.city.name,
        object: item.targetDepartment.address,
        work: item.financeRecords[0]?.work,
        price: item.incomeNDS,
        interaction: undefined,
        customer: `${item.customer.individual.lastName} ${item.customer.individual.firstName}`,
        organization: `${item.targetDepartment.organization.edrpou} | ${item.targetDepartment.organization.name}`,
      })),
    }),
  });

  return {
    applicationsLoading: isLoading || isPreviousData,
    applicationsData: data,
  };
};

export const useApplicationsData = () => {
  const queryClient = useQueryClient();

  return {
    invalidateApplications: () =>
      queryClient.invalidateQueries({
        queryKey: [APPLICATIONS_KEY],
      }),
  };
};

import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import { Service } from '../../services/instance.service';
import { ApplicationPayload } from '../../types/application';
import { useApplicationData } from './useApplication';
import { useApplicationsData } from './useApplications';

export const useAddApplication = () => {
  const { invalidateApplication, setApplicationData } = useApplicationData();
  const { invalidateApplications } = useApplicationsData();

  const { isLoading, mutate } = useMutation({
    mutationFn: (data: ApplicationPayload) =>
      Service.post('/company/claims', data),
    onSuccess: ({ data }) => {
      setApplicationData(data);
      invalidateApplication(data.id);
      invalidateApplications();
    },
    onError: (error: AxiosError) =>
      notification.error({ message: error.message, description: error.code }),
  });

  return {
    loadingAddApplication: isLoading,
    addApplication: mutate,
  };
};

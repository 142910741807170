import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Select, SelectProps } from 'antd';

export interface ItemSelectProps {
  value: string;
  label: string;
}

export interface SelectComponentTypeProps extends SelectProps {
  items: ItemSelectProps[];
  loading?: boolean;
}

export function SelectComponent({
  loading,
  items,
  ...props
}: SelectComponentTypeProps) {
  return (
    <Select
      suffixIcon={
        loading ? (
          <LoadingOutlined style={{ color: 'blue' }} />
        ) : (
          <DownOutlined />
        )
      }
      loading={loading}
      options={items}
      {...props}
    />
  );
}

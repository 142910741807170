import {
  BarsOutlined,
  EnvironmentOutlined,
  MailOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { SIDEBAR_MENU } from '../../App';
import { useUnreadMessages } from '../../hooks/queries/useUnreadMessages';
import { NewApplication } from '../Application/NewApplication/NewApplication';

const { Sider } = Layout;

export function Sidebar() {
  const { t } = useTranslation();
  const location = useLocation();
  const { unreadMessagesCount } = useUnreadMessages();
  const selectedItem = location.pathname.split('/')[1];

  return (
    <Sider
      breakpoint="lg"
      style={{
        paddingTop: '10px',
      }}
      collapsedWidth="0"
    >
      <NewApplication />
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[selectedItem]}
        items={[BarsOutlined, EnvironmentOutlined, UserOutlined].map(
          (icon, index) => ({
            key: SIDEBAR_MENU[index],
            icon: createElement(icon),
            label: (
              <Link
                to={`/${SIDEBAR_MENU[index]}`}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {t(`sections.${SIDEBAR_MENU[index]}`)}
                {!!unreadMessagesCount && !index && (
                  <div
                    style={{
                      display: 'flex',
                      gap: '3px',
                      color: 'red',
                    }}
                  >
                    {unreadMessagesCount} <MailOutlined />
                  </div>
                )}
              </Link>
            ),
          })
        )}
      />
    </Sider>
  );
}

import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import { Locale } from 'antd/lib/locale';
import de_DE from 'antd/lib/locale/de_DE';
import en_US from 'antd/lib/locale/en_US';
import ru_RU from 'antd/lib/locale/ru_RU';
import uk_UA from 'antd/lib/locale/uk_UA';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import 'dayjs/locale/uk';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { MainLayout } from './components/MainLayout/MainLayout';
import { Spinner } from './components/Spinner/Spinner';
import { PUBLIC_URL } from './constants/variables';
import { useProfile } from './hooks/queries/useProfile';

const AuthPage = lazy(() => import('./pages/AuthPage/AuthPage'));
const Applications = lazy(() => import('./pages/Applications/Applications'));
const Application = lazy(() => import('./pages/Applications/Application'));
const MyObjectsPage = lazy(() => import('./pages/MyObjectsPage'));
const ProfilePage = lazy(() => import('./pages/Profile/ProfilePage'));
const ProfileEditPage = lazy(() => import('./pages/Profile/ProfilePageEdite'));
const NotFound = lazy(() => import('./pages/NotFound/NotFound'));

const LOCALES: { [key: string]: Locale } = {
  ua: uk_UA,
  ru: ru_RU,
  en: en_US,
  de: de_DE,
};

const LOGIN = '/login';
export const SIDEBAR_MENU = ['applications', 'my-objects', 'profile'];
const applications = `/${SIDEBAR_MENU[0]}`;

function App() {
  const { i18n } = useTranslation();
  const { isLoadingProfile, dataProfile, errorProfile } = useProfile({
    enabled: true,
  });
  const notAuthorized = errorProfile || typeof dataProfile === 'string';

  const router = createBrowserRouter(
    [
      {
        path: LOGIN,
        element: notAuthorized ? <AuthPage /> : <Navigate to={applications} />,
      },
      {
        element: notAuthorized ? <Navigate to={LOGIN} /> : <MainLayout />,
        children: [
          { path: '/', element: <Navigate to={applications} /> },
          {
            path: applications,
            element: <Applications />,
          },
          { path: `${applications}/:id`, element: <Application /> },
          { path: `/${SIDEBAR_MENU[1]}`, element: <MyObjectsPage /> },
          {
            path: `/${SIDEBAR_MENU[2]}`,
            element: <ProfilePage />,
          },
          { path: `${SIDEBAR_MENU[2]}/edit`, element: <ProfileEditPage /> },
          { path: '*', element: <NotFound /> },
        ],
      },
    ],
    {
      basename: PUBLIC_URL,
    }
  );

  return (
    <ConfigProvider locale={LOCALES[i18n.language.split('-')[0]]}>
      <Suspense fallback={<Spinner />}>
        {isLoadingProfile ? <Spinner /> : <RouterProvider router={router} />}
      </Suspense>
    </ConfigProvider>
  );
}

export default App;

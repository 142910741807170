import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Service } from '../../services/instance.service';
import { ApplicationsTypesType } from '../../types/applications';

export const useApplicationsTypes = () => {
  const { isLoading, data: response } = useQuery({
    queryKey: ['get type'],
    queryFn: () => Service.getAll('/client/claims_types'),
    onError: (error: AxiosError) => {
      console.log('error', error);
    },
  });

  return {
    applicationsTypesLoading: isLoading,
    applicationsTypesData: response?.data as ApplicationsTypesType,
  };
};

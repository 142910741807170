import axios from 'axios';
import { API_URL } from '../constants/variables';

const instance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export const Service = {
  getAll: <T>(url: string) => instance.get<T>(url),
  put: <T>(url: string, id: number | string, payload: T) =>
    instance.put(`${url}/${id}`, JSON.stringify(payload)),
  post: <T>(url: string, payload: T) =>
    instance.post(url, JSON.stringify(payload)),
  getById: (url: string, id: number) => instance.get(`${url}/${id}`),
  patch: <T>(url: string, payload?: T) =>
    instance.patch(url, JSON.stringify(payload)),
  upload<T>(url: string, formData: T) {
    return instance.post(url, formData, {
      headers: { 'Content-type': 'multipart/form-data' },
    });
  },
};

import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Layout, MenuProps, Row, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { useProfile } from '../../hooks/queries/useProfile';
import { ProfileType } from '../../types/profile';
import { getFullUrl, getImageProfile } from '../../utils/getFullUrl';
import { LanguageDropdown } from './LanguageDropdown';

const { Header } = Layout;

enum User {
  Logout = 'LOGOUT',
}

export function HeaderComponent() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { dataProfile } = useProfile({ enabled: false });
  const { image, email, id } = (dataProfile as ProfileType).individual.user;
  const items: MenuProps['items'] = [
    {
      key: User.Logout,
      icon: <LogoutOutlined />,
      label: t('header.logout'),
    },
  ];

  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case User.Logout:
        window.location.href = getFullUrl('/logout');
        break;
      default:
        throw new Error('The selected option is missing');
    }
  };

  return (
    <Header
      style={{
        padding: 0,
        background: colorBgContainer,
        overflow: 'auto',
      }}
    >
      <Row
        justify="space-between"
        style={{ height: '100%', gap: 10 }}
        align="middle"
        wrap={false}
      >
        <img
          src={logo}
          alt="logo"
          style={{ marginLeft: '16px', cursor: 'pointer', height: '100%' }}
          onClick={() => navigate('/')}
        />
        <Row align="middle" wrap={false}>
          <Dropdown menu={{ items, onClick }} placement="bottomRight">
            <Button type="text" style={{ height: '40px' }}>
              <Avatar
                icon={<UserOutlined />}
                src={image && getImageProfile(id, image)}
              />
              <span className="header-user-email" style={{ marginLeft: '5px' }}>
                {email}
              </span>
              <DownOutlined
                style={{
                  fontSize: '12px',
                  marginInlineStart: 'auto',
                  verticalAlign: '-0.2em',
                  marginLeft: '5px',
                }}
              />
            </Button>
          </Dropdown>
          <LanguageDropdown />
        </Row>
      </Row>
    </Header>
  );
}

import { useSearchParams } from 'react-router-dom';
import { SearchParamsKeys } from '../constants/searchParamsKeys';

export const INITIAL_PAGE = 1;
export const INITIAL_LIMIT = 10;

export const useFilters = ({ initialLimit = INITIAL_LIMIT }) => {
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get(SearchParamsKeys.Page)) || INITIAL_PAGE;
  const limit =
    Number(searchParams.get(SearchParamsKeys.Limit)) || initialLimit;
  const searchParamsFilters = JSON.parse(
    searchParams.get(SearchParamsKeys.Filters) || '{}'
  );
  const filters = Object.keys(searchParamsFilters).reduce(
    (result, key) => result + `&search[${key}]=${searchParamsFilters[key]}`,
    ''
  );
  const searchFromParams = searchParams.get(SearchParamsKeys.Search) || '';
  const search = searchFromParams
    ? `&${SearchParamsKeys.Search}=${searchFromParams}`
    : '';

  return { page, limit, filters, search };
};

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Service } from '../../services/instance.service';
import { ProfileType } from '../../types/profile';

const PROFILE_KEY = 'profile';

export const useProfile = ({ enabled }: { enabled: boolean }) => {
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: [PROFILE_KEY],
    enabled: enabled,
    queryFn: () => Service.getAll<ProfileType | string>('/client/profile'),
    select: ({ data }) => data,
  });

  return {
    dataProfile: data,
    errorProfile: error,
    isLoadingProfile: isLoading || (typeof data === 'string' && isFetching),
  };
};

export const useProfileData = () => {
  const queryClient = useQueryClient();

  return {
    invalidateProfile: () =>
      queryClient.invalidateQueries({
        queryKey: [PROFILE_KEY],
      }),
    setProfileData: (data: ProfileType) =>
      queryClient.setQueryData([PROFILE_KEY], { data }),
  };
};

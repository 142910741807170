import { TranslationOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

const items: MenuProps['items'] = [
  {
    key: 'ua',
    label: 'Український',
  },
  {
    key: 'ru',
    label: 'Русский',
  },
  {
    key: 'en',
    label: 'English',
  },
  {
    key: 'de',
    label: 'Deutsch',
  },
];

export function LanguageDropdown() {
  const { i18n } = useTranslation();

  const onClick: MenuProps['onClick'] = ({ key }) => {
    i18n.changeLanguage(key);
  };

  return (
    <Dropdown
      menu={{
        items,
        selectable: true,
        defaultSelectedKeys: [i18n.language.split('-')[0]],
        onClick,
      }}
      placement="bottomRight"
    >
      <Button type="text">
        <TranslationOutlined />
      </Button>
    </Dropdown>
  );
}

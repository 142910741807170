import { ItemSelectProps } from '../components/FormCommon/Select';
import { ApplicationsTypesType } from '../types/applications';

export const objectToSelect = (item: { [key: string]: string }) => {
  if (!item) return [];
  return Object.keys(item)?.map((key) => ({
    value: key,
    label: item[key as keyof ApplicationsTypesType],
  })) as ItemSelectProps[];
};

export const responseDataToSelect = (
  items: { id: number | string; name: string }[]
) => {
  if (!items) return [];
  return items?.map((item) => ({
    value: item.id,
    label: item.name,
  })) as ItemSelectProps[];
};

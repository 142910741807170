//priorities?organizationId=4709
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { Service } from '../../services/instance.service';
import { PriorityType } from '../../types/priority';

export const usePriorities = () => {
  const [isLoading, setIsLaoding] = useState<boolean>(false);
  const [dataPriorities, setDataPriorities] = useState<PriorityType[]>([]);
  const [error, setError] = useState<AxiosError>();

  const sectedById = useCallback(
    async (id: number) => {
      if (isLoading === true) return;
      setIsLaoding(true);
      await Service.getAll(`/sla/priorities?organizationId=${id}`)
        .then((resposne: AxiosResponse) => {
          setDataPriorities(resposne.data.items);
          setIsLaoding(false);
        })
        .catch((error) => {
          setError(error);
          setIsLaoding(false);
        });
    },
    [isLoading]
  );

  return {
    selectIdPriorities: sectedById,
    isLoadingPriorities: isLoading,
    dataPriorities,
    errorPriorities: error,
  };
};

import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import { Service } from '../../services/instance.service';

export const useAddFile = () => {
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: async (data: FormData) => Service.upload('file/upload', data),
    onSuccess: ({ data }) => {
      return data;
    },
    onError: (error: AxiosError) =>
      notification.error({ message: error.message, description: error.code }),
  });

  return {
    loadingFile: isLoading,
    addFile: mutateAsync,
  };
};

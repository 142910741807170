import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Service } from '../../services/instance.service';

export const useApplicationsStatuses = () => {
  const { isLoading, data: response } = useQuery({
    queryKey: ['get statuses'],
    queryFn: () =>
      Service.getAll<{ [key: string]: string }>('/client/claims_statuses'),
    onError: (error: AxiosError) => {
      console.log('error', error);
    },
  });

  return {
    applicationsStatusesLoading: isLoading,
    applicationsStatusesData: response?.data,
  };
};

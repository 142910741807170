import { UseMutateFunction } from '@tanstack/react-query';
import { Divider, Form, Spin, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useApplicationsTypes } from '../../../hooks/queries/useApplicationsTypes';
import { useClientOrganizations } from '../../../hooks/queries/useClientOrganizations';
import { useDepartments } from '../../../hooks/queries/useDepartments';
import { useAddFile } from '../../../hooks/queries/useFiles';
import { usePriorities } from '../../../hooks/queries/usePriorities';
import {
  ApplicationPayload,
  FilesPayloadType,
  FormApplicationValues,
} from '../../../types/application';
import {
  objectToSelect,
  responseDataToSelect,
} from '../../../utils/objectToSelect';
import { ApplicationDrawerFooter } from '../../FormCommon/ApplicationDrawerFooter';
import { SelectComponent } from '../../FormCommon/Select';
import { RedactorComponent } from '../../TextEditor/CKEditor';
import { UploadComponent } from '../../UplaodFile/UploadFile';

interface FormApplicationProps {
  closeDrawer: () => void;
  addApplication: UseMutateFunction<
    AxiosResponse,
    AxiosError,
    ApplicationPayload
  >;
  loadingAddApplication: boolean;
}

export function FormApplication({
  closeDrawer,
  addApplication,
  loadingAddApplication,
}: FormApplicationProps) {
  const [form] = useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { applicationsTypesData, applicationsTypesLoading } =
    useApplicationsTypes();
  const { isLoadingClientOrganizations, dataClientOrganizations } =
    useClientOrganizations();
  const { selectIdPriorities, isLoadingPriorities, dataPriorities } =
    usePriorities();
  const { selectIdDepartments, dataDepartments, isLoadingDepartments } =
    useDepartments();
  const { loadingFile, addFile } = useAddFile();

  const handleSubmitForm = () => {
    form.validateFields().then(async (values: FormApplicationValues) => {
      const files = values?.files || [];
      const fileForPayload: FilesPayloadType[] = [];

      await Promise.all(
        files.map(async (file) => {
          const fD = new FormData();
          fD.append('file', file.originFileObj as Blob);
          await addFile(fD).then((response: AxiosResponse) => {
            const respFile = response.data.file;
            fileForPayload.push({
              file: { id: respFile.id },
              description: respFile.name,
            });
          });
        })
      );

      const payload: ApplicationPayload = {
        files: fileForPayload,
        sla: { priority: { id: values.priority } },
        targetDepartment: { id: values.targetDepartment },
        text: values.text,
        type: values.type,
      };

      addApplication(payload, {
        onSuccess: (response) => {
          navigate(`/applications/${response.data.id}`);
          notification.success({
            message: t('notifications.applicationCreated'),
          });
          closeDrawer();
        },
        onError: () => {
          window.scrollTo(0, 0);
        },
      });
    });
  };

  return (
    <Spin spinning={loadingAddApplication || loadingFile}>
      <Form layout="vertical" form={form} onFinish={handleSubmitForm}>
        <Form.Item
          name={'organization'}
          label={t('label.organization')}
          rules={[{ required: true }]}
        >
          <SelectComponent
            loading={isLoadingClientOrganizations}
            items={responseDataToSelect(dataClientOrganizations)}
            onChange={(id: number) => {
              selectIdDepartments(id);
              selectIdPriorities(id);
              return id;
            }}
          />
        </Form.Item>
        <Form.Item
          name="targetDepartment"
          label={t('label.object')}
          rules={[{ required: true }]}
        >
          <SelectComponent
            loading={isLoadingDepartments}
            items={responseDataToSelect(dataDepartments)}
            disabled={!dataDepartments.length}
          />
        </Form.Item>
        <Form.Item
          name="type"
          label={t('label.type')}
          rules={[{ required: true }]}
        >
          <SelectComponent
            loading={applicationsTypesLoading}
            items={objectToSelect(applicationsTypesData)}
          />
        </Form.Item>
        <Form.Item
          name="priority"
          label={t('label.importance')}
          rules={[{ required: true }]}
        >
          <SelectComponent
            loading={isLoadingPriorities}
            items={responseDataToSelect(dataPriorities)}
            disabled={!dataPriorities.length}
          />
        </Form.Item>
        <Form.Item
          name="text"
          label={t('label.message')}
          rules={[{ required: true }]}
        >
          <RedactorComponent
            onChangeToHtml={(textHtml) => form.setFieldValue('text', textHtml)}
          />
        </Form.Item>
        <UploadComponent />
        <Divider />
        <ApplicationDrawerFooter form={form} closeDrawer={closeDrawer} />
      </Form>
    </Spin>
  );
}
